@font-face {
  font-family: 'Greycliff CF';
  src:
    url('https://cdn.rockalingua.com/production/static/fonts/greycliffcf-demibold-webfont.woff2')
      format('woff2'),
    url('https://cdn.rockalingua.com/production/static/fonts/greycliffcf-demibold-webfont.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Greycliff CF';
  src:
    url('https://cdn.rockalingua.com/production/static/fonts/greycliffcf-demiboldoblique-webfont.woff2')
      format('woff2'),
    url('https://cdn.rockalingua.com/production/static/fonts/greycliffcf-demiboldoblique-webfont.woff')
      format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Greycliff CF';
  src:
    url('https://cdn.rockalingua.com/production/static/fonts/greycliffcf-bold-webfont.woff2')
      format('woff2'),
    url('https://cdn.rockalingua.com/production/static/fonts/greycliffcf-bold-webfont.woff')
      format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Greycliff CF';
  src:
    url('https://cdn.rockalingua.com/production/static/fonts/greycliffcf-boldoblique-webfont.woff2')
      format('woff2'),
    url('https://cdn.rockalingua.com/production/static/fonts/greycliffcf-boldoblique-webfont.woff')
      format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Greycliff CF';
  src:
    url('https://cdn.rockalingua.com/production/static/fonts/greycliffcf-light-webfont.woff2')
      format('woff2'),
    url('https://cdn.rockalingua.com/production/static/fonts/greycliffcf-light-webfont.woff')
      format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Greycliff CF';
  src:
    url('https://cdn.rockalingua.com/production/static/fonts/greycliffcf-lightoblique-webfont.woff2')
      format('woff2'),
    url('https://cdn.rockalingua.com/production/static/fonts/greycliffcf-lightoblique-webfont.woff')
      format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'comic';
  src: url('https://cdn.rockalingua.com/production/static/fonts/comic/ArchitectsDaughter-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'comicSans';
  src: url('https://cdn.rockalingua.com/production/static/fonts/comic/comic_sans_ms-webfont.woff2')
    format('woff2');
  /* src: url('https://cdn.rockalingua.com/production/static/fonts/comic/comic_sans_ms-webfont.woff')
    format('woff'); */
}

@font-face {
  font-family: 'comicSansBold';
  src: url('https://cdn.rockalingua.com/production/static/fonts/comic/comic_sans_ms_bold-webfont.woff2')
    format('woff2');
  src: url('https://cdn.rockalingua.com/production/static/fonts/comic/comic_sans_ms_bold-webfont.woff')
    format('woff');
}

@font-face {
  font-family: 'shortStoryStyle';
  src: url('https://cdn.rockalingua.com/production/static/fonts/comic/Acme-Regular.ttf')
    format('truetype');
}
