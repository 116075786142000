.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}

.ant-input-search-button {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.ant-progress-circle-trail {
  stroke: #ddd;
}

body.ant-scrolling-effect {
  overflow: hidden !important;
}

body:not(.ant-scrolling-effect):not(.fullscreen) {
  overflow: auto !important;
}
